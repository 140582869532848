import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import SVG from 'svgs';
import Link from 'components/atoms/link';
import {
  MAX_PHONE,
  MIN_DESKTOP,
  fontMediumSmall,
  visuallyHidden,
} from 'components/styles';

const InnerWrapper = styled.div`
  background-color: ${({ theme }) => theme.new.surface};
  border-radius: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  padding: ${({ theme }) => theme.spacing.customSpacing('36px')};

  @media (max-width: ${MAX_PHONE}) {
    padding: ${({ theme }) => theme.spacing.BASE_SPACING};
  }
`;

const OuterWrapper = styled.div<{ displayBackButton?: boolean }>`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.spacing.customSpacing('1000px')};
  background-color: transparent;
  padding-top: ${({ displayBackButton, theme }) =>
    displayBackButton
      ? theme.spacing.customSpacing('36px')
      : theme.spacing.customSpacing('48px')};
  padding-bottom: ${({ theme }) => theme.spacing.customSpacing('62px')};

  @media (max-width: ${MIN_DESKTOP}) {
    padding: 0;
  }
`;

const Button = styled(Link)`
  display: flex;
  align-items: center;
  ${fontMediumSmall};
  font-weight: 400;
  margin-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
  color: ${({ theme }) => theme.new.subContent};

  @media (max-width: ${MIN_DESKTOP}) {
    ${visuallyHidden};
  }
`;

const BackIcon = styled(SVG)`
  height: ${({ theme }) => theme.spacing.customSpacing('13px')};
  width: ${({ theme }) => theme.spacing.customSpacing('13px')};
  margin-right: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  fill: ${({ theme }) => theme.new.subContent};
`;

type PageContainerProps = {
  displayBackButton?: boolean;
  buttonText?: string;
  href?: string;
};

const PageContainer = ({
  children,
  displayBackButton,
  buttonText,
  href,
}: PropsWithChildren<PageContainerProps>) => {
  return (
    <OuterWrapper displayBackButton={displayBackButton}>
      {displayBackButton && (
        <Button href={href}>
          <BackIcon icon="chevronLeft" />
          {buttonText}
        </Button>
      )}
      <InnerWrapper className="page-container-inner-wrapper">
        {children}
      </InnerWrapper>
    </OuterWrapper>
  );
};

export default PageContainer;
